@import "../css/variables.module";

.excalidraw {
  .picker-container {
    display: inline-block;
    box-sizing: border-box;
    margin-right: 0.25rem;
  }

  .picker {
    background: var(--popup-bg-color);
    border: 0 solid transparentize($oc-white, 0.75);
    // ˇˇ yeah, i dunno, open to suggestions here :D
    box-shadow: rgb(0 0 0 / 25%) 2px 2px 4px 2px;
    border-radius: 4px;
    position: absolute;
  }

  .picker-container button,
  .picker button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus-visible {
      outline: transparent;
      background-color: var(--button-gray-2);
      & svg {
        opacity: 1;
      }
    }

    &:hover {
      background-color: var(--button-gray-2);
    }

    &:active {
      background-color: var(--button-gray-3);
    }

    &:disabled {
      cursor: not-allowed;
    }

    svg {
      margin: 0;
      width: 36px;
      height: 18px;
      pointer-events: none;
    }
  }

  .picker button {
    padding: 0.25rem 0.28rem 0.35rem 0.25rem;
  }

  .picker-triangle {
    width: 0;
    height: 0;
    position: relative;
    top: -10px;
    :root[dir="ltr"] & {
      left: 12px;
    }

    :root[dir="rtl"] & {
      right: 12px;
    }
    z-index: 10;

    &:before {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 0 9px 10px;
      border-color: transparent transparent transparentize($oc-black, 0.9);
      top: -1px;
    }

    &:after {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 0 9px 10px;
      border-color: transparent transparent var(--popup-bg-color);
    }
  }

  .picker-content {
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 0.5rem;
    border-radius: 4px;
    :root[dir="rtl"] & {
      padding: 0.4rem;
    }
  }

  .picker-keybinding {
    position: absolute;
    bottom: 2px;
    font-size: 0.7em;
    color: var(--keybinding-color);

    :root[dir="ltr"] & {
      right: 2px;
    }

    :root[dir="rtl"] & {
      left: 2px;
    }
    @include isMobile {
      display: none;
    }
  }

  .picker-type-canvasBackground .picker-keybinding {
    color: #aaa;
  }

  .picker-type-elementBackground .picker-keybinding {
    color: $oc-white;
  }

  .picker-swatch[aria-label="transparent"] .picker-keybinding {
    color: #aaa;
  }

  .picker-type-elementStroke .picker-keybinding {
    color: #d4d4d4;
  }

  &.theme--dark {
    .picker-type-elementBackground .picker-keybinding {
      color: $oc-black;
    }
    .picker-swatch[aria-label="transparent"] .picker-keybinding {
      color: $oc-black;
    }
  }
}
