@import "open-color/open-color";

.excalidraw {
  .layer-ui__library-sidebar {
    display: flex;
    flex-direction: column;
  }

  .layer-ui__library {
    display: flex;
    flex-direction: column;

    flex: 1 1 auto;

    .layer-ui__library-header {
      display: flex;
      align-items: center;
      width: 100%;
      margin: 2px 0 15px 0;
      .Spinner {
        margin-right: 1rem;
      }

      button {
        // 2px from the left to account for focus border of left-most button
        margin: 0 2px;
      }
    }
  }

  .layer-ui__sidebar {
    .library-menu-items-container {
      height: 100%;
      width: 100%;
    }
  }

  .library-actions-counter {
    background-color: var(--color-primary);
    color: var(--color-primary-light);
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    position: absolute;
    bottom: -0.25rem;
    right: -0.25rem;
    font-size: 0.625rem;
    pointer-events: none;
  }

  .layer-ui__library-message {
    padding: 2rem;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    justify-content: center;

    span {
      font-size: 0.8em;
    }
  }

  .publish-library-success {
    .Dialog__content {
      display: flex;
      flex-direction: column;
    }

    &-close.ToolIcon_type_button {
      background-color: $oc-blue-6;
      align-self: flex-end;
      &:hover {
        background-color: $oc-blue-8;
      }
      .ToolIcon__icon {
        width: auto;
        font-size: 1rem;
        color: $oc-white;
        padding: 0 0.5rem;
      }
    }
  }

  .library-menu-browse-button {
    margin: 1rem auto;

    padding: 0.875rem 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    border-radius: var(--border-radius-lg);
    background-color: var(--color-primary);
    color: $oc-white;
    text-align: center;
    white-space: nowrap;
    text-decoration: none !important;

    font-weight: 600;
    font-size: 0.75rem;

    &:hover {
      background-color: var(--color-primary-darker);
    }
    &:active {
      background-color: var(--color-primary-darkest);
    }
  }

  &.theme--dark {
    .library-menu-browse-button {
      color: var(--color-gray-100);
    }
  }

  .library-menu-browse-button--mobile {
    min-height: 22px;
    margin-left: auto;
    a {
      padding-right: 0;
    }
  }

  .layer-ui__sidebar__header .dropdown-menu {
    &.dropdown-menu--mobile {
      top: 100%;
    }
    .dropdown-menu-container {
      --gap: 0;
      z-index: 1;
      position: absolute;
      top: 100%;
      left: 0;

      :root[dir="rtl"] & {
        right: 0;
        left: auto;
      }

      width: 196px;
      box-shadow: var(--library-dropdown-shadow);
      border-radius: var(--border-radius-lg);
      padding: 0.25rem 0.5rem;
    }
  }
}
