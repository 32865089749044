.footer-center {
  pointer-events: none;
  & > * {
    pointer-events: all;
  }

  display: flex;
  width: 100%;
  justify-content: flex-start;
}
