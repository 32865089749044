@import "open-color/open-color.scss";
@import "../css/variables.module";

.excalidraw {
  .App-toolbar {
    &.zen-mode {
      .ToolIcon__keybinding,
      .HintViewer {
        display: none;
      }
    }

    &__divider {
      width: 1px;
      height: 1.5rem;
      align-self: center;
      background-color: var(--default-border-color);
      margin: 0 0.5rem;
    }
  }
}
