@import "open-color/open-color";
@import "../../css/variables.module";

.excalidraw {
  .Sidebar {
    &__close-btn,
    &__pin-btn,
    &__dropdown-btn {
      @include outlineButtonStyles;
      width: var(--lg-button-size);
      height: var(--lg-button-size);
      padding: 0;

      svg {
        width: var(--lg-icon-size);
        height: var(--lg-icon-size);
      }
    }

    &__pin-btn {
      &--pinned {
        background-color: var(--color-primary);
        border-color: var(--color-primary);

        svg {
          color: #fff;
        }

        &:hover,
        &:active {
          background-color: var(--color-primary-darker);
        }
      }
    }
  }

  &.theme--dark {
    .Sidebar {
      &__pin-btn {
        &--pinned {
          svg {
            color: var(--color-gray-90);
          }
        }
      }
    }
  }

  .layer-ui__sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    margin: 0;

    :root[dir="rtl"] & {
      left: 0;
      right: auto;
    }

    background-color: var(--sidebar-bg-color);

    box-shadow: var(--sidebar-shadow);

    &--docked {
      box-shadow: none;
    }

    overflow: hidden;
    border-radius: 0;
    width: calc(#{$right-sidebar-width} - var(--space-factor) * 2);

    border-left: 1px solid var(--sidebar-border-color);

    :root[dir="rtl"] & {
      border-right: 1px solid var(--sidebar-border-color);
      border-left: 0;
    }

    padding: 0;
    box-sizing: border-box;

    .Island {
      box-shadow: none;
    }

    .ToolIcon__icon {
      border-radius: var(--border-radius-md);
    }

    .ToolIcon__icon__close {
      .Modal__close {
        width: calc(var(--space-factor) * 7);
        height: calc(var(--space-factor) * 7);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-text);
      }
    }

    .Island {
      --padding: 0;
      background-color: var(--island-bg-color);
      border-radius: var(--border-radius-lg);
      padding: calc(var(--padding) * var(--space-factor));
      position: relative;
      transition: box-shadow 0.5s ease-in-out;
    }
  }

  .layer-ui__sidebar__header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid var(--sidebar-border-color);
  }

  .layer-ui__sidebar__header__buttons {
    display: flex;
    align-items: center;
    gap: 0.625rem;
  }
}
